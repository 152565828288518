import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { remote } from '@boyuai/utils';
import { Link } from 'react-router-dom';
import Card from '../../components/card';
import { Button, Tooltip, Modal } from 'antd';
// import BuyCourse from './buy-course';
// import BuyCourseButton from './buy-course-button';
import Markdown from '../../components/markdown';
import FeedbackModal from './feedback';

import mortarboard from './../../assets/images/mortarboard.svg';
import './lesson-page.less';
import {
    InfoCircleOutlined,
    CheckOutlined,
    EditOutlined,
} from '@ant-design/icons';

class LessonPage extends Component {
    state = {
        lesson: null,
        buyVisible: false,
        feedbackVisible: false,
    };
    componentDidMount() {
        this.fetch();
    }
    async componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.fetch();
        }
    }
    async fetch() {
        const uuid = this.props.match.params.lessonuuid;
        this.setState({ lesson: null });
        const lesson = await remote.$get(
            `/course/lessons/${uuid}`,
            {},
            { hideLoading: true, throwException: 'showMessage' }
        );
        this.setState({ lesson });
    }
    get units() {
        const { lesson } = this.state;
        if (!lesson) {
            return [];
        }
        const units = [];
        lesson.videos.forEach(video =>
            units.push({
                type: 'video',
                uuid: video.uuid,
                order: video.order,
                learningLog: video.learningLog,
            })
        );
        lesson.quizzes.forEach(quiz =>
            units.push({
                type: 'quiz',
                uuid: quiz.uuid,
                order: quiz.order,
                learningLog: quiz.learningLog,
            })
        );
        lesson.jupyters.forEach(jupyter =>
            units.push({
                type: 'jupyter',
                uuid: jupyter.uuid,
                order: jupyter.order,
                learningLog: jupyter.learningLog,
            })
        );
        lesson.codes.forEach(code =>
            units.push({
                type: 'code',
                uuid: code.uuid,
                order: code.order,
                learningLog: code.learningLog,
            })
        );
        return units.sort((a, b) => a.order - b.order);
    }
    get allLearned() {
        return this.units.every(item => item.learningLog);
    }
    onBuyClose = (success, jumpPath) => {
        if (success) {
            if (jumpPath) {
                window.location.href = `${process.env.REACT_APP_SUB_DIRECTORY}${jumpPath}`;
            } else {
                window.location.reload();
            }
        } else {
            this.setState({ buyVisible: false });
        }
    };
    goToFirstUnit = () => {
        const { uuid } = this.props.match.params;
        if (!this.units.length) {
            return;
        }
        const unit = this.units.find(o => !o.learningLog) || this.units[0];
        this.props.history.push(`/course/${uuid}/${unit.type}/${unit.uuid}`);
    };
    onFeedbackCancel = result => {
        this.setState({ feedbackVisible: false });
        if (result) {
            window.location.reload();
        }
    };
    jumpToOtherCourse = (courseTitle, link) => {
        Modal.confirm({
            centered: true,
            icon: <InfoCircleOutlined />,
            title: `该知识点不属于本课程`,
            content: (
                <div style={{ margin: '60px 38px 60px 0', fontSize: 12 }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            textAlign: 'center',
                            marginTop: 20,
                            fontSize: 16,
                        }}
                    >
                        即将跳转至
                        <span
                            style={{
                                textAlign: 'center',
                                fontSize: 20,
                                stroke: 800,
                                marginTop: 20,
                            }}
                        >
                            {courseTitle}
                        </span>
                    </div>
                </div>
            ),
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                window.location.href = link;
            },
        });
    };

    render() {
        const { uuid } = this.props.match.params;
        const { lesson } = this.state;
        const { isSideBarCollapsed } = this.props;
        const authors = [];
        if (lesson) {
            lesson.videos.forEach(video => {
                if (video.author) {
                    authors.push(video.author);
                }
            });
        }
        const hasVideo =
            lesson &&
            (lesson.videos.length ||
                this.props.match.params.uuid === 'bSk7yT7rCa7prYhi');
        const children =
            lesson &&
            lesson.children.filter(
                lesson => lesson.courses && lesson.courses.length
            );
        const parents =
            lesson &&
            lesson.parents.filter(
                lesson => lesson.courses && lesson.courses.length
            );
        return (
            <div className="lesson-page" data-is-collapsed={isSideBarCollapsed}>
                {lesson && (
                    <Card
                        title={lesson.title}
                        authors={authors}
                        isShowCourseTitle={true}
                        boughtCount={hasVideo ? lesson.boughtCount : 0}
                        bought={lesson.bought}
                        boughtExpiresAt={lesson.boughtExpiresAt}
                        hasExpiredAt={lesson.hasExpiredAt}
                    >
                        {/* {hasVideo ? (
                            this.shouldHideLessonPrice() ? null : (
                                <div className="price">
                                    <div className="cloud">
                                        <img
                                            src="https://staticcdn.boyuai.com/materials/2019/10/16/euU4DMZ8mSJTR2uFv-svG.jpg"
                                            alt="云朵"
                                        />
                                        <div className="original-price">
                                            {(lesson.originalPrice >=
                                            lesson.price
                                                ? lesson.originalPrice / 100
                                                : Math.floor(
                                                      (lesson.price * 1.2) / 100
                                                  )
                                            ).toFixed(2)}
                                        </div>
                                        <div className="sell-price">
                                            {(lesson.price / 100).toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="spliter">或</div>
                                    <div className="drop">
                                        <img
                                            src="https://staticcdn.boyuai.com/materials/2019/10/16/TY6YvwFQuNFkHSn54y0v6.jpg"
                                            alt="drop"
                                        />
                                        <div className="original-price">
                                            {lesson.originalPrice >=
                                            lesson.price
                                                ? lesson.originalPrice
                                                : Math.floor(
                                                      (lesson.price * 1.2) / 100
                                                  ) * 100}
                                        </div>
                                        <div className="sell-price">
                                            {lesson.price}
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="price">该知识点正在制作中…</div>
                        )} */}
                        {hasVideo ? (
                            this.renderActions(lesson, uuid)
                        ) : (
                            <div className="buy">
                                <Button disabled>敬请期待</Button>
                            </div>
                        )}
                        {/* <BuyCourse
                            visible={buyVisible}
                            onClose={this.onBuyClose}
                            uuid={lesson.uuid}
                            courseUuid={this.props.match.params.uuid}
                            open
                            isLesson
                        /> */}
                    </Card>
                )}
                {lesson && lesson.desc && (
                    <Card title="相关资源" enableFold>
                        <div className="lesson-desc">
                            <Markdown content={lesson.desc} />
                        </div>
                    </Card>
                )}
                {lesson && (
                    <Card title="知识图谱">
                        <div className="lesson-relations">
                            {this.renderRelationBlock(
                                '先修知识点',
                                parents.length,
                                '#6CD2B6',
                                parents
                            )}
                            {this.renderRelationBlock(
                                '后续知识点',
                                children.length,
                                '#F0755F',
                                children
                            )}
                        </div>
                    </Card>
                )}
            </div>
        );
    }
    // 是否隐藏价格
    shouldHideLessonPrice = () => {
        return false;
        // return /\/course\/cZu18YmweLv10OeV/.test(window.location.pathname); // 动手学的课程需要
    };
    renderActions(lesson, uuid) {
        const { feedbackVisible } = this.state;
        if (!lesson.bought) {
            // 暂时停止购买渠道
            return null;
            // 如果没买 且是 ，则提示不开放购买
            // if (this.shouldHideLessonPrice()) {
            //     return <div className="price">活动期间暂不开放购买</div>;
            // }
            // return (
            //     <>
            //         <Button
            //             type="primary"
            //             onClick={() => this.setState({ buyVisible: true })}
            //         >
            //             购买该知识点
            //         </Button>
            //         <BuyCourseButton courseUUID={uuid} skin="small" />
            //     </>
            // );
        }
        if (this.allLearned && !lesson.feedback) {
            // 已学习，未评价
            return (
                <div className="buy">
                    <Button
                        type="primary"
                        onClick={() => this.setState({ feedbackVisible: true })}
                    >
                        评价本知识点
                    </Button>
                    <FeedbackModal
                        visible={feedbackVisible}
                        onClose={this.onFeedbackCancel}
                        uuid={lesson.uuid}
                    />
                </div>
            );
        }
        return (
            <div className="buy">
                <Button type="primary" onClick={this.goToFirstUnit}>
                    开始学习
                </Button>
            </div>
        );
    }
    renderRelationBlock(title, count, color, lessons) {
        const { uuid } = this.props.match.params;
        return (
            <div className="block" style={{ '--relation-color': color }}>
                <div className="block-title">
                    <img className="icon" src={mortarboard} alt="mortarboard" />
                    <div className="text">{title}</div>
                    <div className="number">{count}</div>
                </div>
                <div className="block-content">
                    {count ? (
                        lessons.map(lesson => {
                            const content = (
                                <>
                                    <div
                                        className="icon"
                                        data-new={!lesson.learned}
                                    >
                                        {lesson.learned ? (
                                            <CheckOutlined />
                                        ) : (
                                            <EditOutlined />
                                        )}
                                    </div>
                                    <div className="title">
                                        <Tooltip title={lesson.title}>
                                            {lesson.title}
                                        </Tooltip>
                                    </div>
                                </>
                            );
                            if (lesson.courses.find(c => c.uuid === uuid)) {
                                // 本课程内
                                return (
                                    <Link
                                        key={lesson.id}
                                        className="lesson"
                                        to={`/course/${uuid}/lesson/${lesson.uuid}`}
                                    >
                                        {content}
                                    </Link>
                                );
                            }
                            // 本课程外
                            const link = `${process.env.REACT_APP_SUB_DIRECTORY}/course/${lesson.courses[0].uuid}/lesson/${lesson.uuid}`;
                            return (
                                <div
                                    onClick={() =>
                                        this.jumpToOtherCourse(
                                            lesson.courses[0].title,
                                            link
                                        )
                                    }
                                    key={lesson.id}
                                    className="lesson"
                                >
                                    {content}
                                </div>
                            );
                        })
                    ) : (
                        <div className="empty">
                            <img
                                src="https://staticcdn.boyuai.com/materials/2019/12/04/H5UeZKcbdB-_Dm_7baItP.jpg"
                                alt="暂无知识点"
                                width="110px"
                                height="124px"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(LessonPage);
