import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from '../../components/markdown';
// import Comment from '@boyuai/comment';
import './index.less';
import { remote } from '@boyuai/utils';
import { Spin, message } from 'antd';

function generateMdText(problem, solution) {
    if (!problem) return;
    const { title } = problem;
    return `
# 「${title}」题解

${
    solution
        ? solution?.replaceAll('\\n', '\n\n')
        : '题解建设中，先来发表你的看法吧~'
}

`;
}

export default function OJ() {
    const { id } = useParams();
    // const commentKey = `OJ-COMMENT-${id}`;
    const [problem, setProblem] = useState(null);
    const [solution, setSolution] = useState(null);
    // const context = useContext(UserContextModule.UserContext);

    useEffect(() => {
        (async () => {
            message.loading('题目加载中...', 0);
            let problem = null;
            try {
                problem = await remote.$get(
                    `/jupyterhub/oj-problems/${id}`,
                    null,
                    { throwException: true }
                );
            } catch (error) {
                // 不做处理
            }
            let solution = null;
            if (problem) {
                solution = await remote.$get(
                    `/jupyterhub/oj-problems/solution/${id}`
                );
            } else if (!isNaN(+id)) {
                problem = await remote.$get(
                    `/jupyterhub/oj-problems-by-real-id/${id}`
                );
                solution = await remote.$get(
                    `/jupyterhub/oj-problems-by-real-id/solution/${id}`
                );
            }
            setProblem(problem);
            setSolution(solution);
            message.destroy();
        })();
    }, [id]);

    return (
        <div className="oj-wrapper">
            {problem ? (
                <>
                    <div className="contents">
                        <Markdown
                            content={generateMdText(
                                problem,
                                solution?.standard_solution,
                                solution?.standard_code
                            )}
                        />
                    </div>
                    {/* <div className="comment-wrapper">
                        <Comment
                            commentKey={commentKey}
                            routePrefix={`oj/${id}`}
                            user={context.user}
                        />
                    </div> */}
                </>
            ) : (
                <div style={{ textAlign: 'center' }}>
                    <Spin tip="题目读取中..." />
                </div>
                // <div>题目读取失败</div>
            )}
        </div>
    );
}
