import './index.less';
import { useEffect } from 'react';

const LearnAI = () => {
    const courses = [
        {
            title: '动手学python',
            link:
                'https://www.boyuai.com/learn/invitation?k=learnai&autoRedirect=1',
            intro:
                '你将结合丰富有趣的案例对python语法基础和计算思维进行学习实践，为后续的AI应用课程打下基础',
            img:
                'https://staticcdn.boyuai.com/user-assets/14398/WpkevhFo6krghpnaBxUGgB/课程封面-动手学Python@3x.png!png',
        },
        {
            title: '玩转数据科学与人工智能使用库',
            link:
                'https://www.boyuai.com/learn/invitation?k=learnai&autoRedirect=1',
            intro:
                '通过文本、图像、数据挖掘领域案例。手把手教你掌握数据科学与AI六大经典python实用库与项目实战。',
            img:
                'https://staticcdn.boyuai.com/user-assets/14398/rVQJNxBCYcx8dUX9Ws8YFg/课程封面-玩转数据科学与人工智能实用库@3x.png!png',
        },
        {
            title: '机器学习基础',
            link: 'https://www.boyuai.com/elites/course/wpp2laqqfjLiz7-HU3_yh',
            intro:
                '”楠哥的讲解清晰明了，有助于机器学习入门者快速了解核心概念，为后续学习进阶内容打下扎实基础。”',
            img:
                'https://staticcdn.boyuai.com/materials/2020/01/02/NbzT0zl1xJJ-8WbO-OxVs.png',
        },
        {
            title: '深度学习',
            link: 'https://www.boyuai.com/elites/course/dTjTDvKdtEHcphowpLskB',
            intro:
                '本课程面向希望较为深入和系统地学习深度学习及其应用相关内容的在校学生及职场人士。',
            img:
                'https://staticcdn.boyuai.com/materials/2020/01/02/X1UVuyljgNBU3ZdeQS54q.png',
        },
    ];

    useEffect(() => {
        document.title = '人工智能训练师训练营';
    }, []);

    return (
        <div className="learn-ai">
            {/* <div className="banner">
                <h1>人工智能训练师训练营</h1>
                <p>Artificial Intelligence Learning Training Camp</p>
            </div> */}
            <div className="intro">
                <img
                    alt=""
                    src="https://staticcdn.boyuai.com/user-assets/14398/2cyi5VHGaLFye6PojkwbGc/20250331-162725.png!png"
                />
            </div>
            <div className="links">
                <h2>在线课程</h2>
                <div className="course-wrapper">
                    {courses.map(course => (
                        <a href={course.link} target="_blank" rel="noreferrer">
                            <div className="course-box">
                                <div className="course-img">
                                    <img alt="" src={course.img} />
                                </div>
                                <p className="course-title">{course.title}</p>
                                <p className="course-intro">{course.intro}</p>
                                <p className="course-link">点击学习</p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
            <div className="contact">
                本平台提供每门课程前三节免费试学体验，欢迎您深度了解课程内容。如需购买完整课程，请报名并咨询相关服务。
                <br />
                <br />
                课程咨询专线：曹老师
                <br />
                <br />
                <img
                    style={{ width: '100px' }}
                    src="https://staticcdn.boyuai.com/user-assets/14398/DehtaxCBPgVBhDgTNE7FPx/ll-qrcode.png!png"
                    alt=""
                />
            </div>
        </div>
    );
};

export default LearnAI;
