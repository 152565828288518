import React, { useCallback, useRef, useState } from 'react';
import { remote } from '@boyuai/utils';
import { Button, Form, InputNumber, message, Radio, Space } from 'antd';
import PhoneInput from 'antd-country-phone-input';

function AdminAddLearnAICourseForUser() {
    const formRef = useRef(null);
    const [phoneList, setPhoneList] = useState([]);
    const [resultList, setResultList] = useState([]);
    // eslint-disable-next-line
    const courseList = [
        {
            id: 29,
            platform: 'bby',
            title: '动手学Python',
        },
        {
            id: 32,
            platform: 'bby',
            title: '玩转数据科学与人工智能实用库',
        },
        {
            id: 'wpp2laqqfjLiz7-HU3_yh',
            platform: 'elites',
            title: '机器学习基础',
        },
        {
            id: 'dTjTDvKdtEHcphowpLskB',
            platform: 'elites',
            title: '深度学习',
        },
    ];
    const handleSubmit = useCallback(
        async values => {
            setResultList([]);
            const { phoneInput, days, courseId } = values;
            if (!phoneInput) {
                message.error('请至少填入一名用户信息');
                return;
            }
            if (phoneInput) {
                const { phone, code } = phoneInput;
                const list = phone
                    .replace(/\s/g, ',')
                    .split(',')
                    .map(item => item.trim())
                    .filter(function(item, index, arr) {
                        return arr.indexOf(item, 0) === index && item;
                    });
                await remote.$post('/course/admin/create-users', {
                    countryCode: code,
                    phones: list,
                });
                const { platform } = courseList.find(
                    item => item.id === courseId
                );
                for (const item of list) {
                    await new Promise(resolve => {
                        remote
                            .$post(
                                '/course/admin/add-learn-ai-course-for-user',
                                {
                                    courseId,
                                    platform,
                                    days,
                                    countryCode: code,
                                    phone: item,
                                },
                                {
                                    throwException: true,
                                }
                            )
                            .then(({ userId }) => {
                                message.success(`+${code} ${item}课程开通成功`);
                                setResultList(prev => [
                                    ...prev,
                                    {
                                        code,
                                        phone: item,
                                        userId,
                                        status: 'success',
                                    },
                                ]);
                                resolve();
                            })
                            .catch(e => {
                                setResultList(prev => [
                                    ...prev,
                                    {
                                        code,
                                        phone: item,
                                        status: 'failure',
                                        message: e.response?.data?.message,
                                    },
                                ]);
                                resolve();
                            });
                    });
                }
            }
            formRef.current.resetFields();
        },
        [courseList]
    );
    return (
        <div style={{ marginTop: '20px' }}>
            <Form onFinish={handleSubmit} ref={formRef}>
                <Form.Item
                    label="手机号（可通过“,”分割填写多个手机号）"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="phoneInput"
                >
                    <PhoneInput
                        onChange={({ phone, code }) => {
                            setPhoneList(
                                phone
                                    ? phone
                                          .replace(/\s/g, ',')
                                          .split(',')
                                          .map(item => item.trim())
                                          .filter(function(item, index, arr) {
                                              return (
                                                  arr.indexOf(item, 0) ===
                                                      index && item
                                              );
                                          })
                                          .map(item => ({ phone: item, code }))
                                    : []
                            );
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="开通课程"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="courseId"
                    rules={[{ required: true }]}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            {courseList.map(item => (
                                <Radio value={item.id}>{item.title}</Radio>
                            ))}
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="开通时限(天)"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 12 }}
                    name="days"
                    initialValue={365}
                    rules={[{ required: true }]}
                >
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 7 }}>
                    <Button type="primary" htmlType="submit">
                        添加课程
                    </Button>
                </Form.Item>
            </Form>

            <div>
                <div>当前输入手机号列表:</div>
                {phoneList.map(item => (
                    <div key={item.phone}>
                        +{item.code} {item.phone}
                    </div>
                ))}
            </div>
            <div>
                <div>充值结果：</div>
                {resultList.map(item => (
                    <div
                        style={{ display: 'flex' }}
                        key={item.phone || item.username}
                    >
                        <div
                            style={{
                                minWidth: 150,
                                marginRight: 20,
                            }}
                        >
                            {item.phone
                                ? `+${item.code} ${item.phone}`
                                : `${item.username}`}
                            {` (id: ${item.userId}) `}
                        </div>
                        <span
                            style={{
                                color:
                                    item.status === 'success' ? 'green' : 'red',
                            }}
                        >
                            {item.status}&nbsp;&nbsp;{item.message}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminAddLearnAICourseForUser;
