import React, { useState, useEffect } from 'react';
import { remote } from '@boyuai/utils';
// import Referral from '../referral';
import Helper from './helper';
import QianrenEnds from './qianren-ends';
import QianrenLaxin from './qianren-laxin';
import Laxin20200501 from './laxin-20200501';
import Boyu618 from './boyu-618';
import Boyu202008 from './boyu-202008';
import Boyu4th from './boyu-4th';

export default function Activity() {
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        remote.$get('/course/activities').then(({ personal, general }) => {
            setLoading(false);
            setActivities([
                ...personal,
                // 通用活动里过滤掉个人活动里已经有了的
                // 因为有些活动要登记个人信息的，个人信息会生成一条在个人活动里
                ...general.filter(
                    o => !personal.find(oo => oo.type === o.type)
                ),
            ]);
        });
    }, []);
    let helperRight = 60;
    let helperBottom = 120;
    // let hasFloatButton = false;
    const comps = activities
        .map(item => {
            let Comp;
            if (item.type === 'qianren-ends') {
                Comp = QianrenEnds;
                // hasFloatButton = true;
                helperRight = 38;
                helperBottom = 120;
            } else if (item.type === 'qianren-laxin') {
                Comp = QianrenLaxin;
                // hasFloatButton = true;
                helperRight = 38;
                helperBottom = 120;
            } else if (item.type === 'laxin-20200501') {
                Comp = Laxin20200501;
                // hasFloatButton = true;
                helperRight = 38;
                helperBottom = 120;
            } else if (item.type === 'boyu-618') {
                Comp = Boyu618;
                // hasFloatButton = true;
                helperRight = 38;
                helperBottom = 120;
            } else if (item.type === 'boyu-202008') {
                Comp = Boyu202008;
                // hasFloatButton = true;
                helperRight = 38;
                helperBottom = 120;
            }
            // 从这里开始合并各个活动
            else if (item.type === 'boyu-4th') {
                Comp = Boyu4th;
                // hasFloatButton = true;
                helperRight = 38;
                helperBottom = 120;
            }
            if (!Comp) {
                return null;
            }
            return {
                activity: item,
                Comp,
            };
        })
        .filter(Boolean);
    if (loading) {
        return null;
    }
    return (
        <div>
            {comps.map(item => (
                <item.Comp
                    key={item.activity.type}
                    activity={item.activity}
                    isCourse={true}
                />
            ))}
            {/* {!hasFloatButton && <Referral />} */}
            <Helper right={helperRight} bottom={helperBottom} />
        </div>
    );
}
